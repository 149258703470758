export const ActivatedApplicationList = () => import('../../components/ActivatedApplicationList.vue' /* webpackChunkName: "components/activated-application-list" */).then(c => wrapFunctional(c.default || c))
export const ApplicationList = () => import('../../components/ApplicationList.vue' /* webpackChunkName: "components/application-list" */).then(c => wrapFunctional(c.default || c))
export const ClientApi = () => import('../../components/ClientApi.vue' /* webpackChunkName: "components/client-api" */).then(c => wrapFunctional(c.default || c))
export const ClientSecret = () => import('../../components/ClientSecret.vue' /* webpackChunkName: "components/client-secret" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GoBackBtn = () => import('../../components/GoBackBtn.vue' /* webpackChunkName: "components/go-back-btn" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelector = () => import('../../components/LanguageSelector.vue' /* webpackChunkName: "components/language-selector" */).then(c => wrapFunctional(c.default || c))
export const LinksInputs = () => import('../../components/LinksInputs.vue' /* webpackChunkName: "components/links-inputs" */).then(c => wrapFunctional(c.default || c))
export const ManageInvitations = () => import('../../components/ManageInvitations.vue' /* webpackChunkName: "components/manage-invitations" */).then(c => wrapFunctional(c.default || c))
export const ManageOrganization = () => import('../../components/ManageOrganization.vue' /* webpackChunkName: "components/manage-organization" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const OrganizationApplicationList = () => import('../../components/OrganizationApplicationList.vue' /* webpackChunkName: "components/organization-application-list" */).then(c => wrapFunctional(c.default || c))
export const OrganizationList = () => import('../../components/OrganizationList.vue' /* webpackChunkName: "components/organization-list" */).then(c => wrapFunctional(c.default || c))
export const RedirectUriInput = () => import('../../components/RedirectUriInput.vue' /* webpackChunkName: "components/redirect-uri-input" */).then(c => wrapFunctional(c.default || c))
export const ScopeList = () => import('../../components/ScopeList.vue' /* webpackChunkName: "components/scope-list" */).then(c => wrapFunctional(c.default || c))
export const SelectSpace = () => import('../../components/SelectSpace.vue' /* webpackChunkName: "components/select-space" */).then(c => wrapFunctional(c.default || c))
export const SpacesList = () => import('../../components/SpacesList.vue' /* webpackChunkName: "components/spaces-list" */).then(c => wrapFunctional(c.default || c))
export const IconsApp = () => import('../../components/icons/App.vue' /* webpackChunkName: "components/icons-app" */).then(c => wrapFunctional(c.default || c))
export const IconsBIMDataMP = () => import('../../components/icons/BIMDataMP.vue' /* webpackChunkName: "components/icons-b-i-m-data-m-p" */).then(c => wrapFunctional(c.default || c))
export const IconsImage = () => import('../../components/icons/Image.vue' /* webpackChunkName: "components/icons-image" */).then(c => wrapFunctional(c.default || c))
export const IconsInstallApp = () => import('../../components/icons/InstallApp.vue' /* webpackChunkName: "components/icons-install-app" */).then(c => wrapFunctional(c.default || c))
export const IconsInvitation = () => import('../../components/icons/Invitation.vue' /* webpackChunkName: "components/icons-invitation" */).then(c => wrapFunctional(c.default || c))
export const IconsOrga = () => import('../../components/icons/Orga.vue' /* webpackChunkName: "components/icons-orga" */).then(c => wrapFunctional(c.default || c))
export const IconsOrganization = () => import('../../components/icons/Organization.vue' /* webpackChunkName: "components/icons-organization" */).then(c => wrapFunctional(c.default || c))
export const IconsSuccessApp = () => import('../../components/icons/SuccessApp.vue' /* webpackChunkName: "components/icons-success-app" */).then(c => wrapFunctional(c.default || c))
export const IconsSuccessOrga = () => import('../../components/icons/SuccessOrga.vue' /* webpackChunkName: "components/icons-success-orga" */).then(c => wrapFunctional(c.default || c))
export const SpaceImage = () => import('../../components/space-image/SpaceImage.vue' /* webpackChunkName: "components/space-image" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageColors = () => import('../../components/space-image/colors.js' /* webpackChunkName: "components/space-image-colors" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/user-avatar/UserAvatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/header/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationMobile = () => import('../../components/header/HeaderNavigationMobile.vue' /* webpackChunkName: "components/header-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const LogosApp = () => import('../../components/logos/App.vue' /* webpackChunkName: "components/logos-app" */).then(c => wrapFunctional(c.default || c))
export const LogosBIMData = () => import('../../components/logos/BIMData.vue' /* webpackChunkName: "components/logos-b-i-m-data" */).then(c => wrapFunctional(c.default || c))
export const LogosGithub = () => import('../../components/logos/Github.vue' /* webpackChunkName: "components/logos-github" */).then(c => wrapFunctional(c.default || c))
export const LogosLinkedin = () => import('../../components/logos/Linkedin.vue' /* webpackChunkName: "components/logos-linkedin" */).then(c => wrapFunctional(c.default || c))
export const LogosMarketPlace = () => import('../../components/logos/MarketPlace.vue' /* webpackChunkName: "components/logos-market-place" */).then(c => wrapFunctional(c.default || c))
export const LogosMarketPlaceSimple = () => import('../../components/logos/MarketPlaceSimple.vue' /* webpackChunkName: "components/logos-market-place-simple" */).then(c => wrapFunctional(c.default || c))
export const LogosTwitter = () => import('../../components/logos/Twitter.vue' /* webpackChunkName: "components/logos-twitter" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/slider/slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageBuilding = () => import('../../components/space-image/fallback-images/image-building.vue' /* webpackChunkName: "components/space-image-building" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageCastle = () => import('../../components/space-image/fallback-images/image-castle.vue' /* webpackChunkName: "components/space-image-castle" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageClinic = () => import('../../components/space-image/fallback-images/image-clinic.vue' /* webpackChunkName: "components/space-image-clinic" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageFactory = () => import('../../components/space-image/fallback-images/image-factory.vue' /* webpackChunkName: "components/space-image-factory" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageHotel = () => import('../../components/space-image/fallback-images/image-hotel.vue' /* webpackChunkName: "components/space-image-hotel" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageHouseStilt = () => import('../../components/space-image/fallback-images/image-house-stilt.vue' /* webpackChunkName: "components/space-image-house-stilt" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageHouse = () => import('../../components/space-image/fallback-images/image-house.vue' /* webpackChunkName: "components/space-image-house" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageHut = () => import('../../components/space-image/fallback-images/image-hut.vue' /* webpackChunkName: "components/space-image-hut" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageModernHouse = () => import('../../components/space-image/fallback-images/image-modern-house.vue' /* webpackChunkName: "components/space-image-modern-house" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageOffice = () => import('../../components/space-image/fallback-images/image-office.vue' /* webpackChunkName: "components/space-image-office" */).then(c => wrapFunctional(c.default || c))
export const SpaceImagePolice = () => import('../../components/space-image/fallback-images/image-police.vue' /* webpackChunkName: "components/space-image-police" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageStadium = () => import('../../components/space-image/fallback-images/image-stadium.vue' /* webpackChunkName: "components/space-image-stadium" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageTower = () => import('../../components/space-image/fallback-images/image-tower.vue' /* webpackChunkName: "components/space-image-tower" */).then(c => wrapFunctional(c.default || c))
export const SpaceImageFallbackImages = () => import('../../components/space-image/fallback-images/index.js' /* webpackChunkName: "components/space-image-fallback-images" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
